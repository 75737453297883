import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface props {
    href?: string;
    src: string;
    alt?: string;
    style?: CSSProperties;
    title?: string;
    noCopyright?: boolean;
    copyrightText?: boolean;
}

const ImageI = styled.img({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    pointerEvents: 'none',
});
const ImageD = styled.div({
    position: 'relative',
    width: '100%',
    height: '100%',
});
export const ImageCopyright = styled.p({
    position: 'absolute',
    margin: 0,
    // bottom: 10,
    // right: 8,
    bottom: 'calc(0.3vh + 0.4vw)',
    right: 'calc(0.4vh + 0.4vw)',
    textTransform: 'uppercase',
    userSelect: 'none',
    fontSize: 'calc(0.8vh + 0.7vw)',
    fontWeight: 400,
    //следващите две са за ImageWithScale
    zIndex: 1,
    transition: '0.5s',
    color: 'white',
    textShadow: '1px 1px 1px rgba(0,0,0,0.8)',
});

export const Image = ({ src, alt, style, noCopyright, copyrightText }: props) => {
    if (noCopyright) return <ImageI src={src} alt={alt || 'Ramus Events'} style={style} />;
    return (
        <ImageD>
            <ImageCopyright>{copyrightText || '© S. Dzhoykev'}</ImageCopyright>
            <ImageI src={src} alt={alt || 'Ramus Events'} style={style} />
        </ImageD>
    );
};

const ImageWithScaleAnchor = styled.a<{ title?: string }>((props) => ({
    clipPath: 'inset(0)',
    position: 'relative',
    display: props.title !== undefined ? 'flex' : 'unset',
    alignItems: props.title !== undefined ? 'center' : '',
    justifyContent: props.title !== undefined ? 'center' : '',
    '&:hover img': {
        transform: 'scale(1.15)',
    },
    '&:hover h3': {
        transform: 'scale(1.15)',
    },
}));

const ImageWithScaleImg = styled.img({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: '0.5s',
    pointerEvents: 'none',
    userSelect: 'none',
});

const ImageTitle = styled.h3({
    position: 'absolute',
    margin: 0,
    zIndex: 1,
    color: 'white',
    textAlign: 'center',
    fontSize: '2vw',
    fontWeight: 300,
    textTransform: 'uppercase',
    transition: '0.5s',
});

export const ImageWithScale = ({ href, src, alt, title, style, noCopyright, copyrightText }: props) => (
    <ImageWithScaleAnchor
        style={{
            ...style,
        }}
        title={title}
        href={href}
    >
        {title && <ImageTitle>{title}</ImageTitle>}
        {!noCopyright && <ImageCopyright>{copyrightText || '© S. Dzhoykev'}</ImageCopyright>}
        <ImageWithScaleImg src={src} alt={alt || 'Ramus Events'} />
    </ImageWithScaleAnchor>
);
