import React, { useEffect, useState } from 'react';
import { Navigation } from './components/Navigation';
import Footer from './components/footer';
import { ArchiveTournamentElement } from './components/ArchiveTournament';
import { HeaderLittleImage } from './components/HeaderImage';
import axios from 'axios';

const Archive = () => {
    document.title = "Ramus' Tournaments";

    const [getArchive, setArchive] = useState([]);
    useEffect(() => {
        async function fetchArchive() {
            try {
                const res = await axios.get('https://www.ramustkd.org/assets/json/archive');
                setArchive(res.data);
            } catch (error) {
                console.log(error);
                setArchive([]);
            }
        }
        fetchArchive();
    }, []);
    return (
        <>
            <Navigation style={{ display: window.screen.width > 800 ? 'flex' : 'none' }} />
            <HeaderLittleImage image='https://ramustkd.org/assets/_MG_0182-Pano.webp' imagePosition={{ objectPosition: '50% 75%' }} />
            {getArchive.map((tournament, index) => (
                <ArchiveTournamentElement tournament={tournament} inverted={(index + 1) % 2 === 0} />
            ))}
            <Footer />
        </>
    );
};

export default Archive;
