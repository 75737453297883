import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import axios from 'axios';
import { ImageCopyright } from './Images';

interface props {
    sectionStyle?: CSSProperties;
    childrenStyle?: CSSProperties;
    children?: ReactNode;
    ref?: any;
    id?: any;
}

const SectionS = styled.section({
    width: '100%',
    // height: '55vh',
    margin: 0,
    paddingTop: '1vh',
    marginBottom: '2vh',
    background: 'linear-gradient(to bottom, #0456FF 0%, #0044d0ef 100%)',
    '@media (max-width: 900px)': {
        height: '49vh',
    },
});

const Title = styled.h2({
    width: 'calc(30vw + 75vh)',
    maxWidth: '95vw',
    margin: 'auto',
    color: 'white',
    fontSize: '4vh',
    fontWeight: 600,
    transform: 'translateX(-2.5px)',
    '@media (max-width: 900px)': {
        width: '90vw',
    },
});

const Border = styled.div({
    width: 'calc(30vw + 75vh)',
    maxWidth: '95vw',
    margin: '0 auto 1vh auto',
    borderBottom: '3px ridge white',
    '@media (max-width: 900px)': {
        width: '90vw',
    },
});

const Children = styled.div({
    width: 'calc(30vw + 75vh)',
    maxWidth: '95vw',
    margin: '0 auto 0 auto',
    // margin: '10vh auto 0 auto',
    // display: 'flex',
    // justifyContent: 'space-between',
    maxHeight: '45vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(5, 0.5fr)',
    gridColumnGap: '3vw',
    gridRowGap: 0,
    '@media (max-width: 900px)': {
        width: '90vw',
        height: '40vh',
    },
});

const NewsA = styled.a<{ gridAreaProp?: number }>(({ gridAreaProp }) => ({
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover img': {
        transform: 'scale(1.1)',
    },
    position: 'relative',
    width: '100%',
    height: '100%',
    filter: 'drop-shadow(0 0 0.9vh rgba(0, 0, 0, 0.2))',
    transition: '0.5s',
    clipPath: 'inset(0)',
    gridArea: gridAreaProp === 0 ? '3 / 1 / 6 / 2' : gridAreaProp === 1 ? '2 / 2 / 5 / 3' : gridAreaProp === 2 ? '1 / 3 / 4 / 4' : '0',
}));

const NewsImg = styled.img({
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    transition: '0.5s',
});
const NewsHeading = styled.h4({
    margin: 0,
    // fontSize: '2.5vh',
    // fontWeight: 600,
    // transform: 'translateY(-0.2vh)',
    width: '96%',
    maxHeight: '20%',
    fontSize: '2vh',
    fontWeight: 400,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '1px 10px 4px 10px',
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    color: 'black',
    zIndex: 1,
    overflow: 'hidden',
    '@media (max-width: 900px)': {
        width: '100%',
        padding: 0,
        fontSize: '1.2vh',
    },
});

export const NewsElement = ({ sectionStyle, childrenStyle }: props) => {
    const getCompetition = () => {
        if (document.title.indexOf('Sofia Open') >= 0) {
            return { competitionNumber: '39', competitionName: 'open' };
        } else if (document.title.indexOf('Sofia Cup') >= 0) {
            return { competitionNumber: '40', competitionName: 'cup' };
        } else if (document.title.indexOf('Skopje Open') >= 0) {
            return { competitionNumber: '41', competitionName: 'skopje' };
        }
        return { competitionNumber: '', competitionName: '' };
    };

    // const [posts, setPosts] = useState([]);
    // useEffect(() => {
    //     async function loadPosts() {
    //         const response = await fetch(
    //             `https://sofia-open.com/wp-json/wp/v2/posts?categories=${
    //                 getCompetition().competitionNumber
    //             }&orderby=date&order=desc&better_featured_image`,
    //         );
    //         if (!response.ok) {
    //             return;
    //         }
    //         const posts = await response.json();
    //         setPosts(posts);
    //     }
    //     loadPosts();
    // }, []);
    const [getNews, setNews] = useState([]);
    useEffect(() => {
        async function fetchNews() {
            try {
                const res = await axios.get('https://sofia-open.com/wp-json/wp/v2/posts?categories=40&orderby=date&order=desc&better_featured_image');
                setNews(res.data);
            } catch (error) {
                console.log(error);
                setNews([]);
            }
        }
        fetchNews();
    }, []);

    const scrollTo = (ref: HTMLElement | null) => {
        const link = window.location.hash.replace('#', '');

        if (window.location.hash.length > 0) {
            if (ref && ref.id === link) {
                ref.scrollIntoView();
            }
        }
    };

    return (
        <SectionS
            style={{
                ...sectionStyle,
            }}
            ref={scrollTo}
            id='news'
        >
            <Title>News</Title>
            <Border />
            <Children
                style={{
                    ...childrenStyle,
                }}
            >
                {/*{getNews.map((post: any, index) => (*/}
                {/*    <NewsA gridAreaProp={index} href={`/news?id=${post.id}`}>*/}
                {/*        <NewsImg src={post.image || 'https://ramustkd.org/assets/RSO24.webp'} alt={post.title} />*/}
                {/*        <NewsHeading>{post.title}</NewsHeading>*/}
                {/*        <ImageCopyright style={{ top: 2, right: 2, opacity: 0.5 }}>© S. Dzhoykev</ImageCopyright>*/}
                {/*    </NewsA>*/}
                {/*))}*/}
                {/*TODO real database*/}
                {getNews.slice(0, 3).map((post: any, index) => (
                    <NewsA gridAreaProp={index} href={`/news?id=${post.id}`}>
                        <NewsImg src={post.better_featured_image?.source_url || 'https://ramustkd.org/assets/RSO24.webp'} alt={post.title.rendered} />
                        <NewsHeading>{post.title.rendered}</NewsHeading>
                        <ImageCopyright style={{ top: 2, right: 2, opacity: 0.5 }}>© S. Dzhoykev</ImageCopyright>
                    </NewsA>
                ))}
            </Children>
            <Button
                href={`/news?i=${getCompetition().competitionName}`}
                title='All news'
                style={{
                    marginRight: window.screen.width > 900 ? '39vh' : '6vw',
                    transform: 'translateY(-6.6vh)',
                }}
            />
        </SectionS>
    );
};
