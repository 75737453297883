import React from 'react';
import styled from 'styled-components';
import { ImageWithScale } from './Images';

interface props {
    information: any;
    inverted?: boolean;
}

const InformationGrid = styled.div({
    height: '55vh',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: '0.7fr repeat(3, 1fr) 0.7fr',
    gridColumnGap: 0,
    gridRowGap: 0,
    marginBottom: '2vh',
    '@media (max-width: 900px)': {
        height: '36vh',
        marginBottom: '1vh',
    },
});

const InformationInfo = styled.div<{ inverted?: boolean }>(({ inverted }) => ({
    gridArea: inverted ? '2 / 1 / 5 / 4' : '2 / 4 / 5 / 7',
    zIndex: 1,
    background: 'linear-gradient(to bottom, #0456FF 0%, #0044d0ef 100%)',
    borderRadius: '.5vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    boxShadow: '3px 3px 10px 0 rgba(0, 0, 0, 0.36), inset 4px 4px 9px 0 rgba(0, 0, 0, 0.1)',
}));

const Heading = styled.h3({
    margin: 0,
    fontSize: '3.5vh',
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    '@media (max-width: 900px)': {
        fontSize: '2.2vh',
    },
});

const SubHeading = styled.h4({
    margin: 0,
    fontSize: '2.5vh',
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    '@media (max-width: 900px)': {
        fontSize: '1.5vh',
    },
});
const Link = styled.a({
    margin: 0,
    fontSize: '2vh',
    color: 'white',
    textAlign: 'center',
    '@media (max-width: 900px)': {
        fontSize: '1.2vh',
    },
});
const Paragraph = styled.p({
    margin: 0,
    fontSize: '2vh',
    color: 'white',
    textAlign: 'center',
    padding: '0 0.5vw 0 0.5vw',
    '@media (max-width: 900px)': {
        fontSize: '1.1vh',
    },
    a: {
        color: 'white',
    },
});

export const InformationElement = ({ information, inverted }: props) => {
    const { heading, subheading, link, linkUrl, paragraph, imageUrl } = information;

    return (
        <InformationGrid>
            <ImageWithScale src={imageUrl} alt={heading} style={{ gridArea: inverted ? '1 / 3 / 6 / 7' : '1 / 1 / 6 / 5' }} />
            <InformationInfo inverted={inverted}>
                {heading && <Heading>{heading}</Heading>}
                {subheading && <SubHeading>{subheading}</SubHeading>}
                {link && <Link href={linkUrl}>{link}</Link>}
                {paragraph && <Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} />}
            </InformationInfo>
        </InformationGrid>
    );
};
