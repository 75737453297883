import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import App from './App';
import SofiaCup from './SofiaCup';
import News from './News';
import SofiaOpen from './SofiaOpen';
import SkopjeOpen from './SkopjeOpen';
import Archive from './Archive';

const routes = createBrowserRouter([
    {
        path: '/',
        element: <App />,
    },
    {
        path: '/cup',
        element: <SofiaCup />,
    },
    {
        path: '/open',
        element: <SofiaOpen />,
    },
    {
        path: '/news',
        element: <News />,
    },
    {
        path: '/skopje',
        element: <SkopjeOpen />,
    },
    {
        path: '/archive',
        element: <Archive />,
    },
    {
        path: '*',
        element: <Navigate to={'/'} />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={routes} />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
