import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface props {
    title?: string;
    image?: string;
    url?: string;
    live?: boolean;
    children?: ReactNode;
    isImage?: boolean;
    viewBox?: string;
}

// interface HoverProps {
//     isHover?: boolean;
// }

export const HeaderMenu = styled.section({
    position: 'absolute',
    zIndex: 4,
    display: 'flex',
    transform: 'translateX(-50%) translateY(-50%)',
    left: '50%',
    backgroundColor: 'white',
    borderRadius: '1vh',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.17)',
    border: 'solid 1px #e1e1e1',
    '@media (max-width: 900px)': {
        // top: '26vh',
        bottom: 'unset',
    },
});

const HeaderElementAnchor = styled.a({
    position: 'relative',
    borderRadius: '1vh',
    // padding: '1vh 1vw 1vh 1vw',
    padding: 'calc(0.4vh + 0.4vw)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    transition: '0.3s',
    textDecoration: 'none',
    color: '#050505',
    '&:hover': {
        backgroundColor: '#0456ff',
        color: 'white',
        transform: 'scale(1.25)',
        fill: 'white',
        zIndex: 5,
    },
    '&:hover svg': {
        fill: 'white',
    },
    '@media (max-width: 900px)': {
        padding: 3,
    },
});

const HeaderElementLive = styled.img({
    position: 'absolute',
    width: '8vw',
    top: '1vh',
    marginRight: '22vh',
    transform: 'rotate(-35deg)',
    '@media (max-width: 900px)': {
        width: '9vh',
        marginRight: '8vh',
    },
});
const HeaderElementImage = styled.img({
    height: '15vh',
    objectFit: 'contain',
    '@media (max-width: 900px)': {
        height: '8vh',
    },
});

const HeaderElementSvg = styled.svg({
    // height: '15vh',
    height: 'calc(8vh + 3.5vw)',
    fill: '#252525',
    '@media (max-width: 900px)': {
        height: '5vh',
    },
});
// const HeaderElementSvg = styled.svg<{
//     isHover?: boolean;
// }>(({ isHover }) => ({
//     fill: isHover ? 'white' : '#252525',
//     height: '15vh',
// }));

const HeaderElementTitle = styled.h3({
    margin: 0,
    fontSize: '3vh',
    fontWeight: 600,
    '@media (max-width: 900px)': {
        fontSize: '1.5vh',
    },
});

export const HeaderElement = ({ title, image, url, live, children, isImage, viewBox }: props) => {
    // const [isHover, setIsHover] = useState(false);
    // const handleMouseEnter = () => {
    //     setIsHover(true);
    // };
    // const handleMouseLeave = () => {
    //     setIsHover(false);
    // };

    return (
        <HeaderElementAnchor href={url || `#${title?.toLowerCase()}`}>
            {live && <HeaderElementLive src='https://sofia-open.com/wp-content/uploads/2024/04/live.png' alt='Live!' />}
            {isImage ? (
                <HeaderElementImage src={image} alt={title} />
            ) : (
                <HeaderElementSvg xmlns='http://www.w3.org/2000/svg' viewBox={viewBox}>
                    {children}
                </HeaderElementSvg>
            )}
            <HeaderElementTitle>{title}</HeaderElementTitle>
        </HeaderElementAnchor>
    );
};
