import React from 'react';
import styled from 'styled-components';
import { VideoWithPlayButton } from './VideoWithPlayButton';
import { Document } from './Documents';
import { ImageWithScale } from './Images';

interface props {
    tournament: any;
    inverted?: boolean;
}

const scrollTo = (ref: HTMLElement | null) => {
    const link = window.location.hash.replace('#', '');

    if (ref && ref.id === link) {
        ref.scrollIntoView();
    }
};

const ArchiveTournamentSection = styled.section<{ inverted?: boolean }>(({ inverted }) => ({
    flexDirection: inverted ? 'row-reverse' : 'row',
    width: 'calc(30vw + 75vh)',
    maxWidth: '95vw',
    margin: 'auto',
    marginBottom: '2vh',
    display: 'flex',
    '@media (max-width: 900px)': {
        width: '95vw',
        flexDirection: 'column',
        marginBottom: 10,
    },
}));

const TournamentInfo = styled.div<{ inverted?: boolean }>(({ inverted }) => ({
    textAlign: inverted ? 'right' : 'left',
    margin: inverted ? '0 0.5vw 0 0' : '0 0 0 0.5vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: '#414141',
    width: '50%',
    '@media (max-width: 900px)': {
        width: 'unset',
        textAlign: 'unset',
    },
}));

const TournamentTitle = styled.h2({
    // fontSize: '4vh',
    fontSize: 'calc(2vh + 1vw)',
    color: '#cf2e2e',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 700,
});

const TournamentSubtitle = styled.h4({
    margin: 0,
});

const TournamentParagraph = styled.p({
    margin: 0,
    fontWeight: 600,
});

const TournamentVideoAndDocs = styled.div<{ inverted?: boolean }>(({ inverted }) => ({
    justifyContent: inverted ? 'flex-end' : 'flex-start',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5vw',
    height: '16vh',
    '@media (max-width: 900px)': {
        height: 100,
        textAlign: 'unset',
    },
}));

export const ArchiveTournamentElement = ({ tournament, inverted }: props) => {
    const { id, title, dates, venue, participants, video, livestream, gallery } = tournament;

    return (
        <ArchiveTournamentSection inverted={inverted} ref={scrollTo} id={id}>
            <ImageWithScale
                src={`https://ramustkd.org/assets/${id}.webp`}
                alt={title + ' Photo'}
                style={{ width: window.screen.width > 900 ? '50%' : 'unset' }}
            />
            <TournamentInfo inverted={inverted}>
                <TournamentTitle>{title}</TournamentTitle>
                <TournamentSubtitle>{dates}</TournamentSubtitle>
                <TournamentSubtitle>{venue}</TournamentSubtitle>
                {participants.length !== 0 && <TournamentSubtitle>{participants}</TournamentSubtitle>}
                {livestream.length !== 0 && (
                    <TournamentParagraph>
                        Livestream:{' '}
                        {livestream.map((day: any, index: number) => {
                            return (
                                <>
                                    <a href={day.href}>{day.title}</a>
                                    {livestream.length !== index + 1 ? ', ' : ''}
                                </>
                            );
                        })}
                    </TournamentParagraph>
                )}
                {gallery.length !== 0 && (
                    <TournamentParagraph>
                        Gallery:{' '}
                        {gallery.map((day: any, index: number) => {
                            return (
                                <>
                                    <a href={day.href}>{day.title}</a>
                                    {gallery.length !== index + 1 ? ', ' : ''}
                                </>
                            );
                        })}
                    </TournamentParagraph>
                )}
                <TournamentVideoAndDocs inverted={inverted}>
                    {video && (
                        <VideoWithPlayButton
                            videoHref={`https://www.youtube.com/watch?v=${video}`}
                            thumbnailSrc={`https://ramustkd.org/assets/${id}.webp`}
                            hoverUp={true}
                        />
                    )}
                    <Document documentHref={`https://ramustkd.org/assets/${id}_Outline.pdf`} documentTitle='Outline' documentAlt={title + ' Outline'} />
                    <Document documentHref={`https://ramustkd.org/assets/${id}_Draws.pdf`} documentTitle='Draws' documentAlt={title + ' Draws'} />
                    <Document documentHref={`https://ramustkd.org/assets/${id}_Results.pdf`} documentTitle='Results' documentAlt={title + ' Results'} />
                    {/*{docs?.map((document: any) => (*/}
                    {/*     <DocsAnchor href={document.href}>*/}
                    {/*         <DocsTitle>{document.title}</DocsTitle>*/}
                    {/*         <Image*/}
                    {/*             style={{ objectFit: 'contain' }}*/}
                    {/*             src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png'*/}
                    {/*             alt={title + ' ' + document.title}*/}
                    {/*         />*/}
                    {/*     </DocsAnchor>*/}
                    {/* ))}*/}
                </TournamentVideoAndDocs>
            </TournamentInfo>
        </ArchiveTournamentSection>
    );
};
