import React from 'react';
import './App.css';
import Footer from './components/footer';
import { HeaderMenu, HeaderElement } from './components/HeaderMenu';
import { HeaderBigImage } from './components/HeaderImage';
import { Navigation } from './components/Navigation';

function App() {
    document.title = 'Ramus Events';
    return (
        <>
            <Navigation style={{ display: window.screen.width > 800 ? 'flex' : 'none' }} />
            <HeaderBigImage title=' ' />
            <HeaderMenu>
                <HeaderElement title='Ramus Sofia Open' image='https://sofia-open.com/wp-content/uploads/2024/04/logo-open.png' url='/open' isImage />
                <HeaderElement title='Ramus Sofia Cup' image='https://sofia-open.com/wp-content/uploads/2024/04/logo-cup.png' url='/cup' live isImage />
                <HeaderElement title='Skopje Open Ramus' image='https://sofia-open.com/wp-content/uploads/2024/04/logo-skopje.png' url='/skopje' isImage />
            </HeaderMenu>
            <section className='App' style={{ marginTop: window.screen.width > 800 ? '13vh' : '4vh' }}>
                <section className='Main'>
                    <section>
                        <h2>Ramus Sofia Open</h2>
                        <div className='MainRow'>
                            <a href='/open'>
                                <img className='MainRowPhoto' src='https://www.ramustkd.org/assets/RSO22-1.webp' alt='Ramus Sofia Open Photo' />
                            </a>
                            <div className='MainRowText'>
                                <p>
                                    Taekwondo club “Ramus” organizes the international taekwondo tournament{' '}
                                    <strong>
                                        <a href='/open'>Ramus Sofia Open</a>
                                    </strong>{' '}
                                    since 2014 and gathers the best taekwondo players from all over the world.
                                </p>

                                <p>
                                    <strong>More than 18,000</strong> competitors from all over the world arrived in Sofia for the title “Champion of Ramus
                                    Sofia Open” and points in the World and Olympic rankings, including Continental, World and Olympic champions such as Adriana
                                    Cerezo, Bradley Sindon, Bianca Walken, Milica Mandic and many others.
                                </p>

                                <p>
                                    <a href='http://worldtaekwondo.org/competition/view.html?nid=138712'>According to World Taekwondo</a>, the{' '}
                                    <a href='/open'>tournament</a> is one of the <strong>biggest and best organized</strong> competitions ever held in Europe.
                                </p>
                            </div>
                        </div>
                        <div className='MainRow' id='rotate'>
                            <div className='MainRowText'>
                                <p>
                                    Таекуондо клуб “Рамус” организира международния таекуондо турнир{' '}
                                    <strong>
                                        <a href='/open'>Рамус София Оупън</a>
                                    </strong>{' '}
                                    от 2014 година и събира най-добрите таекуондисти от целия свят.
                                </p>
                                <p>
                                    За титлата “Шампион на Рамус София Оупън” и точки в световната и олимпийска ранглиста в столицата пристигат{' '}
                                    <strong>над 18 000</strong> състезатели от цял свят, включително Континентални, Световни и Олимпийски Шампиони като Adriana
                                    Cerezo, Bradley Sinden, Bianca Walken, Milica Mandic и много други.
                                </p>
                                <p>
                                    По{' '}
                                    <a href='http://worldtaekwondo.org/competition/view.html?nid=138712' className='ek-link'>
                                        думи на Световната федерация
                                    </a>
                                    , турнирът е едно от <strong>най-големите и най-добре организирани</strong> състезания, провеждани някога в Европа.
                                </p>
                            </div>
                            <a href='/open'>
                                <img className='MainRowPhoto' src='https://www.ramustkd.org/assets/RSO22-2.webp' alt='Ramus Sofia Open Photo' />
                            </a>
                        </div>
                    </section>
                    <section>
                        <h2>Ramus Sofia Cup</h2>
                        <div className='MainRow'>
                            <a href='/cup'>
                                <img className='MainRowPhoto' src='https://www.ramustkd.org/assets/RSC23.webp' alt='Ramus Sofia Cup Photo' />
                            </a>
                            <div className='MainRowText'>
                                <p>
                                    <strong>
                                        <a href='/cup'>Ramus Sofia Cup</a>
                                    </strong>{' '}
                                    is international taekwondo tournament that will celebrate its <strong>11th edition</strong> this year.
                                </p>
                                <p>
                                    We are welcoming you to the charming city of Sofia, which will host three international taekwondo tournaments on the 9. and
                                    10. of November, 2024!
                                </p>
                            </div>
                        </div>
                        <div className='MainRow' id='rotate'>
                            <div className='MainRowText'>
                                <p>
                                    <a href='/cup'>
                                        <strong>Рамус София Оупън</strong>
                                    </a>{' '}
                                    е международно състезание по таекуондо, което ще отбележи своя <strong>11-ти юбилей</strong> тази година.
                                </p>
                                <p>
                                    Приветстваме ви в очарователния град София, който ще бъде домакин на три международни турнира по таекуондо на 9 и 10 ноември
                                    2024 г.!
                                </p>
                            </div>
                            <a href='/cup'>
                                <img className='MainRowPhoto' src='https://www.ramustkd.org/assets/RSC21.webp' alt='Ramus Sofia Cup Photo' />
                            </a>
                        </div>
                    </section>
                    <section>
                        <h2>Ramus Skopje Open G1</h2>
                        <div className='MainRow'>
                            <a href='/skopje'>
                                <img className='MainRowPhoto' src='https://www.ramustkd.org/assets/SOR23.webp' alt='Skopje Open Ramus Photo' />
                            </a>
                            <div className='MainRowText'>
                                <p>
                                    <strong>
                                        <a href='/skopje'>Skopje Open Ramus G1</a>
                                    </strong>{' '}
                                    is the first international taekwondo tournament with WT G1 ranking in North Macedonia!
                                </p>
                                <p>
                                    For its two editions over the past two years, the competition has hosted
                                    <strong> 1200 athletes </strong>
                                    from <strong>over 35 countries</strong>.
                                </p>
                            </div>
                        </div>
                        <div className='MainRow' id='rotate'>
                            <div className='MainRowText'>
                                <p>
                                    <strong>
                                        <a href='/skopje'>Skopje Open Ramus G1</a>
                                    </strong>{' '}
                                    е првиот меѓународен турнир во таеквондо со WT G1 пласман во Северна Македонија!
                                </p>
                                <p>
                                    За своите две изданија во изминатите две години, натпреварот беше домаќин
                                    <strong> 1200 спортисти </strong>
                                    од <strong>над 35 држави</strong>.
                                </p>
                            </div>
                            <a href='/skopje'>
                                <img className='MainRowPhoto' src='https://www.ramustkd.org/assets/SOR24.webp' alt='Skopje Open Ramus Photo' />
                            </a>
                        </div>
                    </section>
                </section>
            </section>
            <Footer />
        </>
    );
}

export default App;
