import styled from 'styled-components';

interface props {
    href: string;
}

const RegistrationS = styled.a({
    height: '40vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url(https://www.ramustkd.org/assets/RSO22-1.webp) fixed 50% 5%',
    backgroundSize: '100%',
    margin: 0,
    fontSize: '7vh',
    fontWeight: 600,
    color: 'white',
    transition: '0.3s',
    textDecoration: 'none',
    textShadow: '0 2px 7px rgba(0,0,0,0.6)',
    '&:hover': {
        fontSize: '12vh',
        cursor: 'pointer',
        '@media (max-width: 900px)': {
            fontSize: '6vh',
        },
    },
    '@media (max-width: 900px)': {
        height: '30vh',
        background: 'url(https://www.ramustkd.org/assets/RSO22-1.webp) 50% 100%',
        backgroundSize: '100%',
        textAlign: 'center',
        fontSize: '5vh',
    },
});
export const Registration = ({ href }: props) => <RegistrationS href={href}>REGISTER NOW!</RegistrationS>;
