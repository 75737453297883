import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Navigation } from './components/Navigation';
import Footer from './components/footer';
import './News.css';
import { HeaderLittleImage } from './components/HeaderImage';

const axiosInstance = axios.create({
    baseURL: 'https://sofia-open.com/wp-json/wp/v2/',
});

const getArticle = (id: string) => {
    return axiosInstance.get(`posts/${id}`);
};

function Articles() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [article, setArticle] = useState<any>();
    const articleId = searchParams.get('id');
    useEffect(() => {
        if (articleId) {
            getArticle(articleId).then((result) => {
                setArticle(result.data);
                document.title = `${result.data.title.rendered}` || 'News';
            });
        } else {
            document.title = 'News';
        }
    }, [searchParams]);

    const dateCreated = new Date(article?.date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const [posts, setPosts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCup, setIsCup] = useState(false);
    const [isSkopje, setIsSkopje] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const newsParam = params.get('i');

        switch (newsParam) {
            case 'open':
                setIsOpen(true);
                setIsCup(false);
                setIsSkopje(false);
                break;
            case 'cup':
                setIsOpen(false);
                setIsCup(true);
                setIsSkopje(false);
                break;
            case 'skopje':
                setIsOpen(false);
                setIsCup(false);
                setIsSkopje(true);
                break;
            default:
                setIsOpen(false);
                setIsCup(false);
                setIsSkopje(false);
                break;
        }
    }, [location.search]);

    useEffect(() => {
        // This useEffect will run whenever isOpen, isCup, or isSkopje change
        async function loadPosts() {
            const categoryParams = getCategoriesParams();
            const response = await fetch(
                `https://sofia-open.com/wp-json/wp/v2/posts?${categoryParams}&orderby=date&order=desc&better_featured_image&per_page=100`,
            );

            if (!response.ok) {
                return;
            }

            const fetchedPosts = await response.json();
            setPosts(fetchedPosts);
        }

        loadPosts();
    }, [location.search, isOpen, isCup, isSkopje]);

    const getCategoriesParams = () => {
        const categories = [];

        if (isOpen) {
            categories.push('39');
        }

        if (isCup) {
            categories.push('40');
        }

        if (isSkopje) {
            categories.push('41');
        }

        return categories.length > 0 ? `categories=${categories.join('+')}` : '';
    };

    const handleCheckboxChange = (checkboxName: 'isOpen' | 'isCup' | 'isSkopje') => {
        switch (checkboxName) {
            case 'isOpen':
                setIsOpen(!isOpen);
                break;
            case 'isCup':
                setIsCup(!isCup);
                break;
            case 'isSkopje':
                setIsSkopje(!isSkopje);
                break;
            default:
                break;
        }
    };

    if (articleId) {
        return (
            <>
                <Navigation style={{ display: window.screen.width > 800 ? 'flex' : 'none' }} />
                <div className='Article'>
                    <div className='ArticleBackground'>
                        <img src='https://sofia-open.com/wp-content/uploads/2024/04/MG_1833-Pano.jpg' alt='Background' />
                    </div>
                    <div className='ArticleHeader'>
                        <img
                            className='ArticleHeaderImage'
                            src={article?.better_featured_image?.source_url || 'https://sofia-open.com/wp-content/uploads/2024/04/MG_5780.jpg'}
                            alt={article?.title?.rendered}
                        />
                        <img className='ArticleHeaderLogo' src='https://sofia-open.com/wp-content/uploads/2024/04/logo-circle.png' alt='Ramus' />
                        <p className='ArticleHeaderInfo'>{dateCreated} – Stiliyan Dzhoykev</p>
                        <div className='ArticleHeaderTitle'>{article?.title?.rendered}</div>
                    </div>
                    <div
                        className='ArticleMain'
                        dangerouslySetInnerHTML={{
                            __html: article?.content?.rendered,
                        }}
                    ></div>
                </div>
                <Footer />
            </>
        );
    } else {
        return (
            <>
                <Navigation style={{ display: window.screen.width > 800 ? 'flex' : 'none' }} />
                <HeaderLittleImage image='https://sofia-open.com/wp-content/uploads/2024/04/MG_1858-scaled.jpg' />
                <div className='Articles'>
                    <div className='ArticlesMain'>
                        <div className='ArticlesList'>
                            {posts.map((post: any, index) => {
                                return (
                                    <a href={`?id=${post.id}`} key={index}>
                                        <img
                                            className='ArticlesListImage'
                                            src={
                                                post.better_featured_image?.media_details.sizes.large.source_url ||
                                                'https://sofia-open.com/wp-content/uploads/2024/04/MG_5780.jpg'
                                            }
                                            alt={post.title.rendered}
                                        />
                                        <h3>{post.title.rendered}</h3>
                                    </a>
                                );
                            })}
                        </div>
                        <div className='ArticlesSidebar'>
                            <div className='ArticlesSidebarTitle'>Filter</div>
                            <div className='ArticlesSidebarFilter'>
                                <div className='ArticlesSidebarFilterOption'>
                                    <input type='checkbox' checked={isOpen} onChange={() => handleCheckboxChange('isOpen')} />
                                    <p>Ramus Sofia Open</p>
                                </div>
                                <div className='ArticlesSidebarFilterOption'>
                                    <input type='checkbox' checked={isCup} onChange={() => handleCheckboxChange('isCup')} />
                                    <p>Ramus Sofia Cup</p>
                                </div>
                                <div className='ArticlesSidebarFilterOption'>
                                    <input type='checkbox' checked={isSkopje} onChange={() => handleCheckboxChange('isSkopje')} />
                                    <p>Skopje Open Ramus</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Articles;
