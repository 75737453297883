import styled from 'styled-components';
import React, { CSSProperties } from 'react';

interface props {
    title?: string;
    href?: string;
    style?: CSSProperties;
}

const Heading = styled.h4({
    margin: 0,
    fontSize: '3vh',
    fontWeight: 600,
    transform: 'translateY(-0.2vh)',
    '@media (max-width: 900px)': {
        fontSize: '2.5vh',
    },
});
const ButtonA = styled.a({
    width: '15vw',
    height: '6vh',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2.5px solid white',
    borderRadius: '3vh',
    transitionDuration: '.3s',
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
        background: 'white',
        color: '#0F51DA',
    },
    '@media (max-width: 900px)': {
        width: '27vw',
        height: '5vh',
    },
});

export const Button = ({ title, href, style }: props) => (
    <ButtonA href={href} style={{ ...style }}>
        <Heading>{title}</Heading>
    </ButtonA>
);
