import React from 'react';
import './footer.css';

const Footer = () => (
    <div className='Footer'>
        <div className='FooterPrediContent'>
            <div className='FooterFirstRow'>
                <div className='FirstRowFirstColumn'>
                    <a href='/'>
                        <img
                            className='FooterLogo'
                            src='https://sofia-open.com/wp-content/uploads/2024/04/logo-ramus-white.png'
                            alt='Taekwondo Club Ramus'
                        />
                    </a>
                </div>
                <div className='FirstRowSecondColumn'>
                    <p>Events by Taekwondo Club Ramus</p>
                    <p>ul. "Kapitan Dimitar Spisarevski" 26, 1592 Sofia, Bulgaria</p>
                    <a href='mailto:office@ramustkd.org'>office@ramustkd.org</a>
                    <p>+359 888 684 548</p>
                    <p>© Made by Stiliyan Dzhoykev</p>
                </div>
                <div className='FirstRowThirdColumn'>
                    <a href='https://www.facebook.com/TaekwondoClubRamus'>
                        <img
                            className='FooterFacebookLogo'
                            src='https://i.postimg.cc/jdNnhWmj/facebook-white.png'
                            alt='Ramus Taekwondo Facebook'
                        />
                    </a>
                    <a href='https://www.instagram.com/taekwondoclubramus/'>
                        <img
                            className='FooterInstagramLogo'
                            src='https://i.postimg.cc/s2pmmZGX/instagram.png'
                            alt='Ramus Taekwondo Instagram'
                        />
                    </a>
                    <a href='https://www.youtube.com/channel/UCbCLBAVRWoWNn3M2lcvdbcA'>
                        <img
                            className='FooterYouTubeLogo'
                            src='https://i.postimg.cc/bJX5vXs9/youtube-white.png'
                            alt='Ramus Taekwondo Instagram'
                        />
                    </a>
                </div>
            </div>
            <div className='FooterSecondRow'>
                <a href='/'>Home</a>
                <a href='https://ramustkd.org' target='_blank' rel='noreferrer'>
                    Taekwondo club Ramus
                </a>
                <a href='/src/News'>News</a>
                <a href='/open'>Ramus Sofia Open</a>
                <a href='/cup'>Ramus Sofia Cup</a>
                <a href='/skopje'>Skopje Open Ramus</a>
                <a href='/src/Archive'>Archive</a>
            </div>
        </div>
        <div className='FooterPrediImage'></div>
        <div className='FooterImage'></div>
    </div>
);

export default Footer;
