import React, { useState } from 'react';
import { Image } from './Images';
import styled from 'styled-components';
import { useElementDimensions } from './useElementDimensions';

interface props {
    documentHref: string | undefined;
    documentTitle?: string;
    documentAlt?: string;
    notAvailable?: boolean;
}
interface documentsPerDayProps {
    documentsPerDay?: any;
}

const DocsAnchor = styled.a<{ notAvailable?: boolean }>(({ notAvailable }) => ({
    filter: notAvailable ? 'saturate(0)' : 'unset',
    display: 'block',
    position: 'relative',
    height: '100%',
    transition: '0.3s',
    '&:hover': {
        transform: notAvailable ? 'unset' : 'translateY(-2.2vh)',
        cursor: notAvailable ? 'default' : 'pointer',
    },
}));

const DocsTitle = styled.h3<{ width: number; height: number }>(({ width, height }) => ({
    margin: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    color: 'black',
    textAlign: 'center',
    bottom: height / 8,
    fontSize: width / 6,
    fontWeight: 600,
    // fontSize: '0.95vw',
    // fontSize: 'calc(1vh + 0.5vw)',
    textTransform: 'uppercase',
}));

export const Document = ({ documentHref, documentTitle, documentAlt, notAvailable }: props) => {
    const [element, setElement] = useState<HTMLAnchorElement | null>(null);
    const { width, height } = useElementDimensions(element);
    return (
        <DocsAnchor href={documentHref} notAvailable={notAvailable} ref={setElement}>
            <DocsTitle width={width} height={height}>
                {documentTitle}
            </DocsTitle>
            <Image style={{ objectFit: 'contain' }} src='https://ramustkd.org/assets/pdf.webp' alt={'Document' || documentAlt} noCopyright />
        </DocsAnchor>
    );
};

const DocumentsPerDayS = styled.div({
    textAlign: 'center',
    background: '#f7f9ff',
    border: 0,
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgba(10,37,64,0.05)',
    transition: 'box-shadow .3s',
    padding: 2,
    '&:hover': {
        boxShadow: '0 3px 10px rgba(10,37,64,0.3)',
    },
});
const Heading = styled.h3({
    margin: 0,
    // fontSize: '3vh',
    fontSize: 'calc(1.5vh + 1vw)',
    fontWeight: 600,
});
const Documents = styled.div({
    marginTop: '2.2vh',
    display: 'flex',
    gap: '0.8vw',
});
export const DocumentsPerDay = ({ documentsPerDay }: documentsPerDayProps) => {
    const { title, dates, outline, draw, weightIn, teams, schedule, single } = documentsPerDay;

    return (
        <DocumentsPerDayS>
            <Heading>{title}</Heading>
            <Heading>{dates}</Heading>
            <Documents>
                {outline && <Document documentHref={outline} documentAlt={`${title} Outline`} documentTitle='Outline' />}
                {draw ? (
                    <Document documentHref={draw} documentAlt='Draw' documentTitle='Draw' />
                ) : (
                    <Document notAvailable documentHref={undefined} documentAlt={`${title} Draw`} documentTitle='Draw' />
                )}
                {weightIn && <Document documentHref={weightIn} documentAlt={`${title} Weight-in`} documentTitle='Weight-in' />}
                {teams && <Document documentHref={teams} documentAlt={`${title} Teams classification`} documentTitle='Teams' />}
                {schedule && <Document documentHref={schedule} documentAlt={`${title} Schedule`} documentTitle='Schedule' />}
                {single && <Document documentHref={single} documentAlt={`${title} Single competitors`} documentTitle='Single c.' />}
            </Documents>
        </DocumentsPerDayS>
    );
};
