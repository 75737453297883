import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

interface props {
    sectionStyle?: CSSProperties;
    childrenStyle?: CSSProperties;
    title?: string;
    children?: ReactNode;
    id?: any;
}

const SectionS = styled.section({
    // width: '65vw',
    width: 'calc(30vw + 75vh)',
    maxWidth: '95vw',
    margin: 'auto',
    marginBottom: '2vh',
    '@media (max-width: 900px)': {
        width: '90vw',
    },
});

const Title = styled.h2({
    margin: 0,
    fontSize: '4vh',
    fontWeight: 600,
    transform: 'translateX(-2.5px)',
    '@media (max-width: 900px)': {
        fontSize: '3vh',
    },
});

const Border = styled.div({
    borderBottom: '3px ridge black',
    margin: '0 0 1vh 0',
    '@media (max-width: 900px)': {
        borderBottom: '2px ridge black',
    },
});

const Children = styled.div({});

export const Section = ({ sectionStyle, childrenStyle, title, children, id }: props) => {
    const scrollTo = (ref: HTMLElement | null) => {
        const link = window.location.hash.replace('#', '');

        if (window.location.hash.length > 0) {
            if (ref && ref.id === link) {
                ref.scrollIntoView();
            }
        }
    };
    return (
        <SectionS
            style={{
                ...sectionStyle,
            }}
            ref={scrollTo}
            id={id || title?.toLowerCase()}
        >
            {title && (
                <>
                    <Title>{title}</Title>
                    <Border />
                </>
            )}
            {childrenStyle ? (
                <Children
                    style={{
                        ...childrenStyle,
                    }}
                >
                    {children}
                </Children>
            ) : (
                <>{children}</>
            )}
        </SectionS>
    );
};
