import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Image } from './Images';
import { ChangingImage } from './ChangingImage';

interface props {
    title?: string;
    info1?: string;
    info2?: string;
    info3?: string;
}

interface littleProps {
    image: string;
    imagePosition?: CSSProperties;
    title?: string;
}

const HeaderLittleSection = styled.section({
    // height: '30vh',
    height: 'calc(13vh + 9vw)',
    backgroundColor: 'black',
    marginBottom: '3vh',
    pointerEvents: 'none',
    userSelect: 'none',
    '@media (max-width: 900px)': {
        height: 'calc(20vh + 13vw)',
    },
});

const HeaderLittleTitle = styled.h1({
    zIndex: 1,
    top: '13.5%',
    position: 'absolute',
    margin: 0,
    // fontSize: '12.7vh',
    fontSize: 'calc(6.35vh + 3.1vw)',
    fontWeight: 400,
    width: '100%',
    textAlign: 'center',
    color: 'white',
    textShadow: '1px 1px 2px rgba(0,0,0,0.4)',
    whiteSpace: 'nowrap',
    '@media (max-width: 900px)': {
        fontSize: '12vw',
        whiteSpace: 'unset',
        // top: 60,
        top: '6%',
    },
});

const HeaderBigSection = styled.section({
    position: 'relative',
    height: '75vh',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none',
    userSelect: 'none',
    '@media (max-width: 900px)': {
        height: '30vh',
    },
});

const HeaderBigTitleAndInfo = styled.div({
    zIndex: 3,
    // bottom: '30%',
    position: 'absolute',
    // fontSize: '12.7vh',
    transform: 'translateX(-0.4%)',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const HeaderBigInfoOutD = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: '98.5%',
    transform: 'translateX(0.5%) translateY(26%)',
});

const HeaderBigInfoP = styled.p<{ id?: number }>(({ id }) => ({
    margin: 0,
    padding: 5,
    fontSize: 'calc(1vh + 0.5vw)',
    textTransform: 'uppercase',
    fontWeight: 500,
    backgroundColor: id === 0 ? '#C90000' : id === 1 ? 'white' : '0',
    color: id === 0 ? 'white' : id === 1 ? '#C90000' : '0',
}));

const HeaderBigTitle = styled.h1({
    margin: 0,
    // transform: 'translateX(-50%) translateY(270%)',
    fontSize: 'calc(6.35vh + 3.1vw)',
    fontWeight: 400,
    lineHeight: 1,
    textShadow: '1px 1px 2px rgba(0,0,0,0.4)',
    whiteSpace: 'nowrap',
    '@media (max-width: 900px)': {
        fontSize: '10vw',
    },
});

const HeaderBigGradient = styled.div({
    width: '100%',
    height: '75vh',
    zIndex: 2,
    position: 'absolute',
    '@media (max-width: 900px)': {
        height: '30vh',
    },
});

export const HeaderBigImage = ({ title, info1, info2, info3 }: props) => (
    <HeaderBigSection>
        <HeaderBigTitleAndInfo style={{ bottom: window.screen.width > 800 ? 'calc(7vh + 3.5vw)' : '5vh' }}>
            <HeaderBigInfoOutD style={{ transform: window.screen.width > 800 ? 'translateX(0.5%) translateY(26%)' : 'translateX(0.5%)' }}>
                {info1 && <HeaderBigInfoP id={0}>{info1}</HeaderBigInfoP>}
                {info2 && <HeaderBigInfoP id={1}>{info2}</HeaderBigInfoP>}
                {info3 && <HeaderBigInfoP id={0}>{info3}</HeaderBigInfoP>}
            </HeaderBigInfoOutD>
            <HeaderBigTitle>{title || document.title}</HeaderBigTitle>
        </HeaderBigTitleAndInfo>
        <HeaderBigGradient style={{ background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 70%' }} />
        {/*<Image*/}
        {/*    style={{*/}
        {/*        zIndex: 1,*/}
        {/*        ...imagePosition,*/}
        {/*    }}*/}
        {/*    src={image}*/}
        {/*    alt={document.title + ' Header'}*/}
        {/*></Image>*/}
        <ChangingImage />
    </HeaderBigSection>
);

export const HeaderLittleImage = ({ image, imagePosition, title }: littleProps) => (
    <HeaderLittleSection>
        <HeaderLittleTitle>{title || document.title}</HeaderLittleTitle>
        <Image
            style={{
                opacity: '0.8',
                ...imagePosition,
            }}
            src={image}
            alt={document.title + ' Header'}
        ></Image>
    </HeaderLittleSection>
);
