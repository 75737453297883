import React from 'react';

export const InformationIcon = () => (
    <>
        <path d='m27.14,14.95c5.74,0,10.4,4.66,10.4,10.4s-4.66,10.4-10.4,10.4-10.4-4.66-10.4-10.4h0c0-5.74,4.66-10.39,10.4-10.4Zm0,18.3c4.36,0,7.9-3.54,7.9-7.9s-3.54-7.9-7.9-7.9c-4.36,0-7.9,3.54-7.9,7.9,0,1.45.4,2.87,1.15,4.1,1.43,2.37,3.99,3.81,6.75,3.8Z'></path>
        <path d='m66.05,74.19h0c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h0c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25Zm-5.01,0h-1c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h1c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25Zm-6,0h-1c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h1c.69,0,1.25.56,1.25,1.25h0c0,.69-.56,1.25-1.25,1.25h0Zm-6,0h-1c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h1c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25Z'></path>
        <path d='m43.61,74.19h-3.66c-3.72.05-6.78-2.94-6.82-6.66-.05-3.72,2.94-6.78,6.66-6.82.05,0,.11,0,.16,0h25.62c2.34.04,4.27-1.83,4.31-4.18s-1.83-4.27-4.18-4.31c-.04,0-.09,0-.13,0h-31.37l-5.98,10.36c-.35.6-1.11.8-1.71.46-.19-.11-.35-.27-.46-.46l-15.63-27.08c-5.6-9.24-2.64-21.26,6.59-26.85,9.24-5.6,21.26-2.64,26.85,6.59,1.37,2.27,2.27,4.79,2.64,7.42.31,2.27.22,4.58-.26,6.82-.46,2.13-1.27,4.16-2.4,6.02l-5.41,9.38c-.34.6-1.1.81-1.7.47-.6-.34-.81-1.1-.47-1.7,0,0,0,0,0-.01l5.42-9.39.02-.03c4.89-8.05,2.33-18.54-5.71-23.43-8.05-4.89-18.54-2.33-23.43,5.71-3.31,5.44-3.31,12.27,0,17.71l.02.03,14.56,25.21,5.26-9.11c.22-.39.64-.63,1.08-.62h32.1c3.72-.05,6.78,2.94,6.82,6.66.05,3.72-2.94,6.78-6.66,6.82-.05,0-.11,0-.16,0h-25.63c-2.34-.04-4.27,1.83-4.31,4.18s1.83,4.27,4.18,4.31c.04,0,.09,0,.13,0h3.66c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25h0Z'></path>
    </>
);

export const ScheduleIcon = () => (
    <>
        <path d='m51.12,17.3h-20c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h20c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25Z'></path>
        <path d='m71.1,72.2H8.88c-.69,0-1.25-.56-1.25-1.25V16.05c0-.69.56-1.25,1.25-1.25h3.66c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25h-2.41v52.4h59.73V17.3h-2.41c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h3.66c.69,0,1.25.56,1.25,1.25v54.9c0,.69-.56,1.25-1.25,1.25Z'></path>
        <path d='m18.12,7.8h5c.69,0,1.25.56,1.25,1.25v12c0,.69-.56,1.25-1.25,1.25h-5c-.69,0-1.25-.56-1.25-1.25v-12c0-.69.56-1.25,1.25-1.25Zm3.75,2.5h-2.5v9.5h2.5v-9.5Z'></path>
        <path d='m56.12,7.8h6c.69,0,1.25.56,1.25,1.25v12c0,.69-.56,1.25-1.25,1.25h-6c-.69,0-1.25-.56-1.25-1.25v-12c0-.69.56-1.25,1.25-1.25Zm4.75,2.5h-3.5v9.5h3.5v-9.5Z'></path>
        <path d='m71.12,32.3H9.12c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h62c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25Z'></path>
        <path d='m18.12,38.8h9c.69,0,1.25.56,1.25,1.25v7c0,.69-.56,1.25-1.25,1.25h-9c-.69,0-1.25-.56-1.25-1.25v-7c0-.69.56-1.25,1.25-1.25Zm7.75,2.5h-6.5v4.5h6.5v-4.5Z'></path>
        <path d='m36.12,38.8h9c.69,0,1.25.56,1.25,1.25v7c0,.69-.56,1.25-1.25,1.25h-9c-.69,0-1.25-.56-1.25-1.25v-7c0-.69.56-1.25,1.25-1.25Zm7.75,2.5h-6.5v4.5h6.5v-4.5Z'></path>
        <path d='m53.12,38.8h9c.69,0,1.25.56,1.25,1.25v7c0,.69-.56,1.25-1.25,1.25h-9c-.69,0-1.25-.56-1.25-1.25v-7c0-.69.56-1.25,1.25-1.25Zm7.75,2.5h-6.5v4.5h6.5v-4.5Z'></path>
        <path d='m18.12,52.8h9c.69,0,1.25.56,1.25,1.25v8c0,.69-.56,1.25-1.25,1.25h-9c-.69,0-1.25-.56-1.25-1.25v-8c0-.69.56-1.25,1.25-1.25Zm7.75,2.5h-6.5v5.5h6.5v-5.5Z'></path>
        <path d='m36.12,52.8h9c.69,0,1.25.56,1.25,1.25v8c0,.69-.56,1.25-1.25,1.25h-9c-.69,0-1.25-.56-1.25-1.25v-8c0-.69.56-1.25,1.25-1.25Zm7.75,2.5h-6.5v5.5h6.5v-5.5Z'></path>
        <path d='m53.12,52.8h9c.69,0,1.25.56,1.25,1.25v8c0,.69-.56,1.25-1.25,1.25h-9c-.69,0-1.25-.56-1.25-1.25v-8c0-.69.56-1.25,1.25-1.25Zm7.75,2.5h-6.5v5.5h6.5v-5.5Z'></path>
    </>
);

export const DocumentIcon = () => (
    <>
        <path
            className='cls-1'
            d='m76.6,72.36H3.39c-.69,0-1.25-.56-1.25-1.25V14.38c0-.69.56-1.25,1.25-1.25h3.66c.69,0,1.25.56,1.25,1.25s-.56,1.25-1.25,1.25h-2.41v54.24h70.71V15.63h-2.41c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25h3.66c.69,0,1.25.56,1.25,1.25v56.74c0,.69-.56,1.25-1.25,1.25h0Z'
        ></path>
        <path
            className='cls-1'
            d='m40,68.7c-.69,0-1.25-.56-1.25-1.25,0-3.72,3.02-6.74,6.74-6.74h22.54V10.14h-22.54c-2.34,0-4.24,1.9-4.24,4.24,0,.69-.56,1.25-1.25,1.25s-1.25-.56-1.25-1.25c0-3.72,3.02-6.74,6.74-6.74h23.79c.69,0,1.25.56,1.25,1.25v53.08c0,.69-.56,1.25-1.25,1.25h-23.79c-2.34,0-4.24,1.9-4.24,4.24,0,.69-.56,1.25-1.25,1.25Z'
        ></path>
        <path
            className='cls-1'
            d='m39.97,68.08c-.64,0-1.17-.48-1.24-1.11-.24-2.14-2.06-3.76-4.21-3.76H10.72c-.69,0-1.25-.56-1.25-1.25V8.89c0-.69.56-1.25,1.25-1.25h23.79c3.72,0,6.74,3.02,6.74,6.74,0,.69-.56,1.25-1.25,1.25s-1.25-.56-1.25-1.25c0-2.34-1.9-4.24-4.24-4.24H11.97v50.58h22.54c3.43,0,6.32,2.57,6.7,5.98.08.69-.41,1.3-1.1,1.38-.05,0-.09,0-.14,0Z'
        ></path>
        <path
            className='cls-1'
            d='m40,68.7c-.69,0-1.25-.56-1.25-1.25,0-.16,0-.32-.03-.48-.08-.69.41-1.31,1.1-1.38.68-.08,1.3.41,1.38,1.09.03.25.04.51.04.77,0,.69-.56,1.25-1.25,1.25Z'
        ></path>
        <path
            className='cls-1'
            d='m34.39,34.96h11c.69,0,1.25.56,1.25,1.25v20c0,.69-.56,1.25-1.25,1.25h-11c-.69,0-1.25-.56-1.25-1.25v-20c0-.69.56-1.25,1.25-1.25Zm9.75,2.5h-8.5v17.5h8.5v-17.5Z'
        ></path>
        <path
            className='cls-1'
            d='m40,32.1c-3.72,0-6.74-3.02-6.74-6.74s3.02-6.74,6.74-6.74,6.74,3.02,6.74,6.74h0c0,3.72-3.02,6.74-6.74,6.74Zm0-10.98c-2.34,0-4.24,1.9-4.24,4.24s1.9,4.24,4.24,4.24,4.24-1.9,4.24-4.24h0c0-2.34-1.9-4.24-4.24-4.24Z'
        ></path>
    </>
);

export const PhotoIcon = () => (
    <path d='M10,6.536c-2.263,0-4.099,1.836-4.099,4.098S7.737,14.732,10,14.732s4.099-1.836,4.099-4.098S12.263,6.536,10,6.536M10,13.871c-1.784,0-3.235-1.453-3.235-3.237S8.216,7.399,10,7.399c1.784,0,3.235,1.452,3.235,3.235S11.784,13.871,10,13.871M17.118,5.672l-3.237,0.014L12.52,3.697c-0.082-0.105-0.209-0.168-0.343-0.168H7.824c-0.134,0-0.261,0.062-0.343,0.168L6.12,5.686H2.882c-0.951,0-1.726,0.748-1.726,1.699v7.362c0,0.951,0.774,1.725,1.726,1.725h14.236c0.951,0,1.726-0.773,1.726-1.725V7.195C18.844,6.244,18.069,5.672,17.118,5.672 M17.98,14.746c0,0.477-0.386,0.861-0.862,0.861H2.882c-0.477,0-0.863-0.385-0.863-0.861V7.384c0-0.477,0.386-0.85,0.863-0.85l3.451,0.014c0.134,0,0.261-0.062,0.343-0.168l1.361-1.989h3.926l1.361,1.989c0.082,0.105,0.209,0.168,0.343,0.168l3.451-0.014c0.477,0,0.862,0.184,0.862,0.661V14.746z'></path>
);

export const RegistrationIcon = () => (
    <>
        <g strokeWidth='0'></g>
        <g strokeLinecap='round' strokeLinejoin='round'></g>
        <g>
            <g>
                <g>
                    <g>
                        <path d='M511.915,266.261c-0.021-0.576-0.235-1.131-0.341-1.707c-0.171-0.832-0.299-1.664-0.661-2.411 c-0.043-0.085-0.021-0.192-0.064-0.277L489.515,219.2c-1.813-3.584-5.504-5.867-9.536-5.867H362.667v-96 c0-0.171-0.085-0.299-0.085-0.448c-0.021-0.427-0.192-0.832-0.256-1.259c-0.171-1.003-0.341-1.963-0.768-2.859 c-0.021-0.064-0.021-0.128-0.043-0.192l-21.333-42.667c-1.835-3.627-5.525-5.909-9.557-5.909H181.291 c-4.032,0-7.723,2.283-9.536,5.888l-21.333,42.667c-0.064,0.128-0.043,0.277-0.107,0.405c-0.213,0.469-0.256,1.003-0.405,1.493 c-0.256,0.875-0.512,1.749-0.533,2.645c0,0.085-0.043,0.149-0.043,0.235V192H32c-4.032,0-7.723,2.283-9.536,5.888L1.131,240.555 c-0.043,0.107-0.043,0.213-0.085,0.32c-0.299,0.661-0.405,1.387-0.576,2.112c-0.149,0.661-0.384,1.301-0.405,1.984 c0,0.128-0.064,0.235-0.064,0.363v192C0,443.221,4.779,448,10.667,448h490.667c5.888,0,10.667-4.779,10.667-10.667V266.667 C512,266.517,511.915,266.389,511.915,266.261z M362.667,234.667h110.72L484.053,256H362.667V234.667z M187.904,85.333h136.149 l10.667,21.333H177.237L187.904,85.333z M38.592,213.333h110.741v21.333H27.925L38.592,213.333z M490.667,426.667H21.333V256H160 c5.888,0,10.667-4.779,10.667-10.667V128h170.667v95.893c0,0.043-0.021,0.064-0.021,0.107s0.021,0.064,0.021,0.085v42.581 c0,5.888,4.779,10.667,10.667,10.667h138.667V426.667z'></path>{' '}
                        <path d='M270.763,171.456c-3.989-1.664-8.576-0.768-11.627,2.304l-21.333,21.333c-4.16,4.16-4.16,10.923,0,15.083 c4.16,4.16,10.923,4.16,15.083,0l3.115-3.115V288c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.688-10.667V181.333 C277.355,177.003,274.752,173.12,270.763,171.456z'></path>{' '}
                        <path d='M426.667,384c-5.888,0-10.667-4.8-10.667-10.667c0-5.888-4.779-10.667-10.667-10.667 c-5.888,0-10.667,4.779-10.667,10.667c0,17.643,14.357,32,32,32s32-14.357,32-32c0-8.192-3.093-15.659-8.171-21.333 c5.077-5.675,8.171-13.141,8.171-21.333c0-17.643-14.357-32-32-32s-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667 c5.888,0,10.667-4.779,10.667-10.667C416,324.8,420.779,320,426.667,320s10.667,4.8,10.667,10.667 c0,5.867-4.779,10.667-10.667,10.667S416,346.112,416,352c0,5.888,4.779,10.667,10.667,10.667s10.667,4.8,10.667,10.667 C437.333,379.179,432.555,384,426.667,384z'></path>{' '}
                        <path d='M117.333,309.291c0-17.643-14.357-32-32-32c-17.643,0-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667 s10.667-4.779,10.667-10.667c0-5.867,4.779-10.667,10.667-10.667S96,303.424,96,309.291c0,10.901-4.267,21.163-11.968,28.885 L56.448,365.76c-3.051,3.051-3.968,7.637-2.304,11.627C55.787,381.397,59.691,384,64,384h42.667 c5.888,0,10.667-4.8,10.667-10.688c0-5.888-4.779-10.667-10.667-10.667H89.749l9.365-9.365 C110.869,341.504,117.333,325.888,117.333,309.291z'></path>
                    </g>
                </g>
            </g>
        </g>
    </>
);

export const NewsIcon = () => (
    <path d='M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385'></path>
);

export const LivestreamIcon = () => (
    <path d='M17.919,4.633l-3.833,2.48V6.371c0-1-0.815-1.815-1.816-1.815H3.191c-1.001,0-1.816,0.814-1.816,1.815v7.261c0,1.001,0.815,1.815,1.816,1.815h9.079c1.001,0,1.816-0.814,1.816-1.815v-0.739l3.833,2.478c0.428,0.226,0.706-0.157,0.706-0.377V5.01C18.625,4.787,18.374,4.378,17.919,4.633 M13.178,13.632c0,0.501-0.406,0.907-0.908,0.907H3.191c-0.501,0-0.908-0.406-0.908-0.907V6.371c0-0.501,0.407-0.907,0.908-0.907h9.079c0.502,0,0.908,0.406,0.908,0.907V13.632zM17.717,14.158l-3.631-2.348V8.193l3.631-2.348V14.158z'></path>
);
export const ResultsIcon = () => (
    <>
        <g strokeWidth='0.001'></g>
        <g strokeLinecap='round' strokeLinejoin='round'></g>
        <g>
            <g>
                <g>
                    <g>
                        <path d='M511.915,266.261c-0.021-0.576-0.235-1.131-0.341-1.707c-0.171-0.832-0.299-1.664-0.661-2.411 c-0.043-0.085-0.021-0.192-0.064-0.277L489.515,219.2c-1.813-3.584-5.504-5.867-9.536-5.867H362.667v-96 c0-0.171-0.085-0.299-0.085-0.448c-0.021-0.427-0.192-0.832-0.256-1.259c-0.171-1.003-0.341-1.963-0.768-2.859 c-0.021-0.064-0.021-0.128-0.043-0.192l-21.333-42.667c-1.835-3.627-5.525-5.909-9.557-5.909H181.291 c-4.032,0-7.723,2.283-9.536,5.888l-21.333,42.667c-0.064,0.128-0.043,0.277-0.107,0.405c-0.213,0.469-0.256,1.003-0.405,1.493 c-0.256,0.875-0.512,1.749-0.533,2.645c0,0.085-0.043,0.149-0.043,0.235V192H32c-4.032,0-7.723,2.283-9.536,5.888L1.131,240.555 c-0.043,0.107-0.043,0.213-0.085,0.32c-0.299,0.661-0.405,1.387-0.576,2.112c-0.149,0.661-0.384,1.301-0.405,1.984 c0,0.128-0.064,0.235-0.064,0.363v192C0,443.221,4.779,448,10.667,448h490.667c5.888,0,10.667-4.779,10.667-10.667V266.667 C512,266.517,511.915,266.389,511.915,266.261z M362.667,234.667h110.72L484.053,256H362.667V234.667z M187.904,85.333h136.149 l10.667,21.333H177.237L187.904,85.333z M38.592,213.333h110.741v21.333H27.925L38.592,213.333z M490.667,426.667H21.333V256H160 c5.888,0,10.667-4.779,10.667-10.667V128h170.667v95.893c0,0.043-0.021,0.064-0.021,0.107s0.021,0.064,0.021,0.085v42.581 c0,5.888,4.779,10.667,10.667,10.667h138.667V426.667z'></path>
                        <path d='M270.763,171.456c-3.989-1.664-8.576-0.768-11.627,2.304l-21.333,21.333c-4.16,4.16-4.16,10.923,0,15.083 c4.16,4.16,10.923,4.16,15.083,0l3.115-3.115V288c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.688-10.667V181.333 C277.355,177.003,274.752,173.12,270.763,171.456z'></path>
                        <path d='M426.667,384c-5.888,0-10.667-4.8-10.667-10.667c0-5.888-4.779-10.667-10.667-10.667 c-5.888,0-10.667,4.779-10.667,10.667c0,17.643,14.357,32,32,32s32-14.357,32-32c0-8.192-3.093-15.659-8.171-21.333 c5.077-5.675,8.171-13.141,8.171-21.333c0-17.643-14.357-32-32-32s-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667 c5.888,0,10.667-4.779,10.667-10.667C416,324.8,420.779,320,426.667,320s10.667,4.8,10.667,10.667 c0,5.867-4.779,10.667-10.667,10.667S416,346.112,416,352c0,5.888,4.779,10.667,10.667,10.667s10.667,4.8,10.667,10.667 C437.333,379.179,432.555,384,426.667,384z'></path>{' '}
                        <path d='M117.333,309.291c0-17.643-14.357-32-32-32c-17.643,0-32,14.357-32,32c0,5.888,4.779,10.667,10.667,10.667 s10.667-4.779,10.667-10.667c0-5.867,4.779-10.667,10.667-10.667S96,303.424,96,309.291c0,10.901-4.267,21.163-11.968,28.885 L56.448,365.76c-3.051,3.051-3.968,7.637-2.304,11.627C55.787,381.397,59.691,384,64,384h42.667 c5.888,0,10.667-4.8,10.667-10.688c0-5.888-4.779-10.667-10.667-10.667H89.749l9.365-9.365 C110.869,341.504,117.333,325.888,117.333,309.291z'></path>
                    </g>
                </g>
            </g>
        </g>
    </>
);

export const MenuIcon = () => (
    <svg fill='black' viewBox='0 0 24 24' height='100%' width='100%'>
        <path
            fill='currentColor'
            d='M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 12.032a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM3 17.064a1 1 0 100 2h18a1 1 0 000-2H3z'
        />
    </svg>
);

export const MenuClose = () => (
    <svg fill='black' viewBox='0 0 24 24' height='100%' width='100%'>
        <path d='M13.46 12L19 17.54V19h-1.46L12 13.46 6.46 19H5v-1.46L10.54 12 5 6.46V5h1.46L12 10.54 17.54 5H19v1.46L13.46 12z' />
    </svg>
);
