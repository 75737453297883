import styled from 'styled-components';
import { Image } from './Images';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import { MenuClose, MenuIcon } from '../images/Icons';

interface navSProps {
    style?: CSSProperties;
}
interface navElementProps {
    title: string;
    href: string;
    children?: ReactNode;
}
interface navSocialProps {
    src: string;
    href: string;
}

const NavS = styled.section({
    height: 'calc(2vh + 2vw)',
    // height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
});

const NavLogoA = styled.a({
    padding: '5px 0 5px 15px',
});

const NavElementsD = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const NavD = styled.div({
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    // padding: '8px calc(100vw / 80) 8px calc(100vw / 80)',
    '&:hover div': {
        display: 'flex',
        flexDirection: 'column',
    },
    '&:hover>a': {
        background: '#c90000',
        color: 'white',
        cursor: 'pointer',
    },
});

const NavChildren = styled.div({
    display: 'none',
    position: 'absolute',
    top: '99%',
    width: '100%',
    zIndex: 5,
    background: 'white',
    color: 'black',
});

const NavA = styled.a({
    textTransform: 'uppercase',
    fontSize: 'calc(0.49vh + 0.49vw)',
    fontWeight: 500,
    padding: 'calc(1vh + 1vw) calc(100vw / 80) calc(1vh + 1vw) calc(100vw / 80)',
    // padding: '30px calc(100vw / 80) 30px calc(100vw / 80)',
    textDecoration: 'none',
    color: 'black',
    transition: '0.3s',
});

const NavDrop = styled.a({
    textTransform: 'uppercase',
    fontSize: 'calc(0.49vh + 0.49vw)',
    fontWeight: 500,
    padding: 'calc(0.5vh + 0.5vh) calc(100vw / 80) calc(0.5vh + 0.5vh) calc(100vw / 80)',
    textDecoration: 'none',
    color: 'black',
    transition: '0.3s',
    '&:hover': {
        background: '#c90000',
        color: 'white',
        cursor: 'pointer',
    },
});

const NavElement = ({ title, children, href }: navElementProps) => (
    <NavD>
        <NavA href={href}>{title}</NavA>
        {children && <NavChildren>{children}</NavChildren>}
    </NavD>
);

const NavSocialA = styled.a({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px 0 10px',
    '&:hover': {
        background: '#c90000',
        transition: '0.3s',
        cursor: 'pointer',
    },
    '&:hover img': {
        filter: 'brightness(600%)',
        transition: '0.3s',
    },
});
const NavSocial = ({ src, href }: navSocialProps) => (
    <NavSocialA href={href}>
        <Image src={src} noCopyright style={{ height: '80%', objectFit: 'contain' }} />
    </NavSocialA>
);

export const Navigation = ({ style }: navSProps) => {
    const [getArchive, setArchive] = useState([]);
    useEffect(() => {
        async function fetchArchive() {
            try {
                const res = await axios.get('https://www.ramustkd.org/assets/json/archive');
                setArchive(res.data);
            } catch (error) {
                console.log(error);
                setArchive([]);
            }
        }
        fetchArchive();
    }, []);

    const [openedMenu, setOpenedMenu] = useState(false);
    const handleButtonClick = () => {
        if (openedMenu) {
            setOpenedMenu(false);
            console.log(openedMenu);
        } else {
            setOpenedMenu(true);
            console.log(openedMenu);
        }
    };

    if (window.screen.width < 800) {
        return (
            <>
                <NavButton onClick={handleButtonClick} openedMenu={openedMenu}>
                    {openedMenu ? <MenuClose /> : <MenuIcon />}
                </NavButton>
                <NavMobile openedMenu={openedMenu}>
                    <NavMobileLogo href='/'>
                        <Image src='https://ramustkd.org/assets/logo.webp' alt='Ramus Events' noCopyright style={{ objectFit: 'contain' }} />
                    </NavMobileLogo>
                    <NavMobileElement href='/'>Home</NavMobileElement>
                    <NavMobileElement href='https://ramustkd.org'>Taekwondo Club Ramus</NavMobileElement>
                    <NavMobileElement href='/news'>News</NavMobileElement>
                    <NavMobileElement href='/open'>Ramus Sofia Open</NavMobileElement>
                    <NavMobileElement href='/cup'>Ramus Sofia Cup</NavMobileElement>
                    <NavMobileElement href='/skopje'>Skopje Open Ramus</NavMobileElement>
                    <NavMobileElement href='/archive'>Tournaments Archive</NavMobileElement>
                    <NavMobileSocialD>
                        <NavMobileSocial src='https://i.postimg.cc/G2rKgX3Y/fb.png' href='https://www.facebook.com/TaekwondoClubRamus' />
                        <NavMobileSocial src='https://i.postimg.cc/1zVb17Zj/ig.png' href='https://www.instagram.com/taekwondoclubramus/' />
                        <NavMobileSocial src='https://i.postimg.cc/hPCLbS79/yt.png' href='https://www.youtube.com/@taekwondoclubramus8466' />
                    </NavMobileSocialD>
                </NavMobile>
            </>
        );
    } else
        return (
            <NavS style={style}>
                <NavLogoA href='/'>
                    <Image src='https://ramustkd.org/assets/logo.webp' alt='Ramus Events' noCopyright style={{ objectFit: 'contain' }} />
                </NavLogoA>
                <NavElementsD>
                    <NavElement title='Home' href='/' />
                    <NavElement title='Taekwondo Club Ramus' href='https://ramustkd.org' />
                    <NavElement title='News' href='/news' />
                    <NavElement title='Ramus Sofia Open' href='/open'>
                        <NavDrop href='/open#info'>Information</NavDrop>
                        <NavDrop href='/open#docs'>Documents</NavDrop>
                        <NavDrop href='/open#live'>Livestream</NavDrop>
                        <NavDrop href='/open#results'>Results</NavDrop>
                    </NavElement>
                    <NavElement title='Ramus Sofia Cup' href='/cup'>
                        <NavDrop href='/cup#information'>Information</NavDrop>
                        <NavDrop href='/cup#documents'>Documents</NavDrop>
                        <NavDrop href='/cup#photos'>Photos</NavDrop>
                        <NavDrop href='/cup#news'>News</NavDrop>
                        {/*<NavDrop href='/cup#livestream'>Livestream</NavDrop>*/}
                    </NavElement>
                    <NavElement title='Skopje Open Ramus' href='/skopje'>
                        <NavDrop href='/skopje#info'>Information</NavDrop>
                        <NavDrop href='/skopje#docs'>Documents</NavDrop>
                        <NavDrop href='/skopje#live'>Livestream</NavDrop>
                        <NavDrop href='/skopje#results'>Results</NavDrop>
                    </NavElement>
                    <NavElement title='Tournaments Archive' href='/archive'>
                        {getArchive.map((tournament: any, index) => (
                            <NavDrop href={`/Archive#${tournament.id}`} key={index}>
                                {tournament.title}
                            </NavDrop>
                        ))}
                    </NavElement>
                    <NavSocial src='https://i.postimg.cc/G2rKgX3Y/fb.png' href='https://www.facebook.com/TaekwondoClubRamus' />
                    <NavSocial src='https://i.postimg.cc/1zVb17Zj/ig.png' href='https://www.instagram.com/taekwondoclubramus/' />
                    <NavSocial src='https://i.postimg.cc/hPCLbS79/yt.png' href='https://www.youtube.com/@taekwondoclubramus8466' />
                </NavElementsD>
            </NavS>
        );
};

const NavButton = styled.button<{ openedMenu?: boolean }>(({ openedMenu }) => ({
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 6,
    width: 40,
    height: 40,
    borderRadius: 10,
    cursor: 'pointer',
}));

const NavMobile = styled.div<{ openedMenu?: boolean }>(({ openedMenu }) => ({
    visibility: openedMenu ? 'visible' : 'hidden',
    opacity: openedMenu ? 1 : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '0.4s',
    position: 'absolute',
    width: '100%',
    background: 'white',
    zIndex: 5,
    padding: '15px 0 15px 0',
}));

const NavMobileLogo = styled.a({
    width: 100,
});

const NavMobileElement = styled.a({
    width: '100%',
    textAlign: 'center',
    padding: '8px 0 8px 0',
    textWrap: 'nowrap',
    color: 'black',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
        background: '#c90000',
        color: 'white',
    },
});

const NavMobileSocialD = styled.div({
    display: 'flex',
    height: 70,
});

const NavMobileSocialA = styled.a({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        background: '#c90000',
        transition: '0.3s',
        cursor: 'pointer',
    },
    '&:hover img': {
        filter: 'brightness(600%)',
        transition: '0.3s',
    },
});
const NavMobileSocial = ({ src, href }: navSocialProps) => (
    <NavMobileSocialA href={href}>
        <Image src={src} noCopyright style={{ height: '80%', objectFit: 'contain' }} />
    </NavMobileSocialA>
);
