import React, { useEffect, useState } from 'react';
import { Navigation } from './components/Navigation';
import Footer from './components/footer';
import './SkopjeOpen.css';
import { HeaderBigImage } from './components/HeaderImage';
import { HeaderElement, HeaderMenu } from './components/HeaderMenu';
import { DocumentIcon, InformationIcon, LivestreamIcon, NewsIcon, ResultsIcon, ScheduleIcon } from './images/Icons';

function SkopjeOpen() {
    document.title = 'Skopje Open Ramus 2024 G1';

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function loadPosts() {
            const response = await fetch(`https://sofia-open.com/wp-json/wp/v2/posts?categories=41&orderby=date&order=desc&better_featured_image`);
            if (!response.ok) {
                return;
            }
            const posts = await response.json();
            setPosts(posts);
        }
        loadPosts();
    }, []);

    const scrollTo = (ref: HTMLElement | null) => {
        const link = window.location.hash.replace('#', '');

        if (ref && ref.id === link) {
            ref.scrollIntoView();
        }
    };

    return (
        <>
            <Navigation />
            <HeaderBigImage />
            <HeaderMenu>
                <HeaderElement title='Information' viewBox='0 1 80 80'>
                    <InformationIcon />
                </HeaderElement>
                <HeaderElement title='Schedule' viewBox='0 0 80 80'>
                    <ScheduleIcon />
                </HeaderElement>
                <HeaderElement title='Documents' viewBox='0 1 80 80'>
                    <DocumentIcon />
                </HeaderElement>
                <HeaderElement title='Livestream' viewBox='0 0 20 20'>
                    <LivestreamIcon />
                </HeaderElement>
                <HeaderElement title='News' viewBox='1 0.5 18 18'>
                    <NewsIcon />
                </HeaderElement>
                <HeaderElement title='Results' viewBox='0 0 512 512'>
                    <ResultsIcon />
                </HeaderElement>
            </HeaderMenu>
            <div className='SkopjeOpen'>
                <div className='Main'>
                    <div className='Information' ref={scrollTo} id='information'>
                        <h2>Information</h2>
                        <span className='line'></span>
                        <div className='InformationGridLeft'>
                            <div className='InformationGridLeftBack'>
                                <img src='https://sofia-open.com/wp-content/uploads/2024/04/MG_6689.jpg' alt='Asics Arena' />
                            </div>
                            <div className='InformationGridLeftFront'>
                                <h3>Venue</h3>
                                <h4>Sports Center Jane Sandanski Arena</h4>
                                <a href='https://maps.app.goo.gl/wMttEQP8rbPG5GpH7'>Ashom blvd, 1000, Skopje, North Macedonia</a>
                                <p>
                                    Jane Sandanski Arena is an indoor sports arena located in the Aerodrom Municipality of Skopje, North Macedonia. It has a
                                    capacity of 6,000. The sports complex includes a 5-star hotel with a spa center, swimming pool and a sky bar. Tennis and
                                    table courts, fitness and aerobics center and clubs headquarters are within the sports complex.
                                </p>
                            </div>
                        </div>
                        <div className='InformationGridRight'>
                            <div className='InformationGridRightBack'>
                                <img src='https://upload.wikimedia.org/wikipedia/commons/7/75/Спортски_центар_„Јане_Сандански“.jpg' alt='Asics Arena' />
                            </div>
                            <div className='InformationGridRightFront'>
                                <h3>Accommodation</h3>
                                <h4>Sports Center Jane Sandanski Arena</h4>
                                <a href='https://maps.app.goo.gl/wMttEQP8rbPG5GpH7'>Ashom blvd, 1000, Skopje, North Macedonia</a>
                                <p>
                                    All participants are offered preferential prices by the OC in the partner hotels. Make a reservation through{' '}
                                    <a href='mailto:office@ramustkd.org'>office@ramustkd.org</a>
                                </p>
                                <p>
                                    Transportation is <strong>not </strong>included in the hotel accommodation.
                                </p>
                            </div>
                        </div>
                        <div className='InformationGridLeft'>
                            <div className='InformationGridLeftBack'>
                                <img src='https://sofia-open.com/wp-content/uploads/2024/04/MG_4458.jpg' alt='Asics Arena' />
                            </div>
                            <div className='InformationGridLeftFront'>
                                <h3>Competition Rules</h3>
                                <a href='http://www.worldtaekwondo.org/rules-wt/rules.html'>WT Competition Rules (September 1, 2023)</a>
                                <p>Single elimination tournament</p>
                                <p>Best of three competition format.</p>
                                <p>
                                    <strong>
                                        30.03.2024 (Saturday) – Cadets and Juniors – E1 event;<br></br>
                                        31.03.2024 (Sunday) – Seniors – WT G1 event;
                                    </strong>
                                </p>
                                <p>
                                    Seniors 3(2) x 2min (60 sec. break)<br></br> Juniors and Cadets: 3(2) x 1,5 min (60 sec. break)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='Schedule' ref={scrollTo} id='schedule'>
                        <h2>Event Schedule</h2>
                        <span className='line'></span>
                        <img src='https://sofia-open.com/wp-content/uploads/2024/04/sor-schedule.jpg' alt='Event Schedule' />
                    </div>
                    <div className='Documents' ref={scrollTo} id='documents'>
                        <h2>Documents</h2>
                        <span className='line'></span>
                        <div className='DocumentsList'>
                            <a href='https://skopje-open.ramustkd.org/wp-content/uploads/2024/03/SOR24G1_Outline.pdf'>
                                <h3 className='DocumentsListName'>Outline</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Outline' />
                            </a>
                            <a href='https://skopje-open.ramustkd.org/wp-content/uploads/2024/03/SOR24G1_Draw_Seniors.pdf' id=''>
                                <h3 className='DocumentsListName'>Draw Seniors</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Draw' />
                            </a>
                            <a href='https://skopje-open.ramustkd.org/wp-content/uploads/2024/03/SOR24G1_Teams_Seniors.pdf' id=''>
                                <h3 className='DocumentsListName'>Teams Seniors</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Teams' />
                            </a>
                            <a href='https://www.tpss2021.eu/Resultdetail?ID=mBbd2ecCxDOGyErdr7nMbA%3d%3d&EventID=pvuU%2f5EvaiLo%2fH0i8PzYtQ%3d%3d' id=''>
                                <h3 className='DocumentsListName'>Weight-in</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Random Weight-in' />
                            </a>
                            {/*<a href='' id='nocolor'>*/}
                            {/*    <h3 className='DocumentsListName'>Single comp.</h3>*/}
                            {/*    <img*/}
                            {/*        src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png'*/}
                            {/*        alt='Single competitors'*/}
                            {/*    />*/}
                            {/*</a>*/}
                        </div>
                    </div>
                    <div className='Live' ref={scrollTo} id='livestream'>
                        <h2>Livestream</h2>
                        <span className='line'></span>
                        <div className='LiveRow'>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court1.png' alt='Court 1' />
                                <iframe
                                    src='https://www.youtube.com/embed/uUtHE9bvJQo'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court-2.png' alt='Court 2' />
                                <iframe
                                    src='https://www.youtube.com/embed/MvgAmlZ6RgY?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court-3.png' alt='Court 3' />
                                <iframe
                                    src='https://www.youtube.com/embed/QdCYFw537NY?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                        <div className='LiveRow'>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/11/COURT-5.png' alt='Court 5' />
                                <iframe
                                    src='https://www.youtube.com/embed/I91J4cjpAZw?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court-4.png' alt='Court 4' />
                                <iframe
                                    src='https://www.youtube.com/embed/AFN_DyrEzqU?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div className='News' ref={scrollTo} id='news'>
                        <h2>News</h2>
                        <span className='line'></span>
                        <div className='NewsRow'>
                            {posts.slice(0, 3).map((post: any, index) => {
                                return (
                                    <a key={index} id={`news${index + 1}`} href={`/news?id=${post.id}`}>
                                        <img
                                            src={post.better_featured_image?.source_url || 'https://sofia-open.com/wp-content/uploads/2024/04/MG_6689.jpg'}
                                            alt={post.title.rendered}
                                        />
                                        <h3>{post.title.rendered}</h3>
                                    </a>
                                );
                            })}
                        </div>
                        <a href='/news?i=skopje' className='NewsAll'>
                            <h3>All news</h3>
                        </a>
                    </div>
                    <div className='Results' ref={scrollTo} id='results'>
                        <h2>Results</h2>
                        <span className='line'></span>

                        <h2 className='ResultCategory'>MEN -54 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>MANENKOV Maksym</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>GEORGIEV Hristiyan</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>KUSKU Alaattin</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>MARWAN Mussa</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN -58 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>KAPLAN Enes</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>CALIK Merdan</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>BELANOVIC Uros</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>NOWAK Lukasz</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN -63 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>CHRZANOWSKI Mateusz</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>CANI Juirdo</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>PAPIEROWSKI Dominik</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>CAPUANO Lorenzo</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN -68 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>DUGAC Grga</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>JORGENSEN Otto</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>AVRAMOV Bozhidar</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>ERER Berkay</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN -74 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>LUKIC Nikola</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>TAKOV Stefan</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>DOYLE Ryan</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>STORCHAK Danylo</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN -80 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>KOSTENEVYCH Kostiantyn</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>HUSIC Nedzad</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>TAHRAOUI Yanis</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>CRISTESCU Mihai adrian</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN -87 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>DIVKOVIC Patrik</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>KARAJLOVIC Ivan</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>AJDUKOVIC Dimitrije</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>KINSANSI Steve</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>MEN +87 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>GEORGIEVSKI Dejan</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>EL YAZIDI Omar</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>OUASSOU Zakariya</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>AGBOGBE-SALANON Osmund</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -46 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>BAYECH Rivka</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>GAYE Serrah</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>KOLAREK Nika</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>MAKAS Dzejla</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -49 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>KRAJEWSKA Alicja</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>BUTTARELLI Aurelie</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>DANSHYNA Oleksandra</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>KRAJEWSKA Nikola</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -53 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>BOKAN Andrea</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>MUSICKI Jovana</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>STANKOVIC Vanja</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>AVDAGIC Ada</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -57 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>BUGARINOVIC Ema</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>IYIN Meryem elif</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>MILIC Nikolina</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>COMAN Gabriela bianca</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -62 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>RELJIKJ Miljana</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>SZPAK Julia</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>GASPA Cristina</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>YIGITALP Hatice pinar</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -67 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>MEHMEDOVIC Nadina</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>CAKAL Sefika</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>HADDAD Khalida</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>MEHNANA Ines</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN -73 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>PETROVIC Iva</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>DIAKITE Bintou</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>KLEPAC Nika</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>DEMIR Kardelen</p>
                            </div>
                        </div>

                        <h2 className='ResultCategory'>WOMEN +73 KG</h2>
                        <div className='ResultsRow'>
                            <div className='Result1'>
                                <h2 className='ResultNumber'>1</h2>
                                <p>OUASSOU Mouna</p>
                            </div>
                            <div className='Result2'>
                                <h2 className='ResultNumber'>2</h2>
                                <p>HRVAT Amina</p>
                            </div>
                            <div className='Result3'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>KAVUKCUOGLU Zehra begum</p>
                            </div>
                            <div className='Result4'>
                                <h2 className='ResultNumber'>3</h2>
                                <p>URNAUT Nusa</p>
                            </div>
                        </div>

                        <h2 className='ResultCategoryImages'>JUNIORS</h2>
                        <div className='ResultsRowImages'>
                            <img
                                src='https://skopje-open.ramustkd.org/wp-content/uploads/2024/04/2570_Medallist-per-category-Juniors_Page_1.jpg'
                                alt='Results1'
                            />
                            <img
                                src='https://skopje-open.ramustkd.org/wp-content/uploads/2024/04/2570_Medallist-per-category-Juniors_Page_2.jpg'
                                alt='Results2'
                            />
                            <img
                                src='https://skopje-open.ramustkd.org/wp-content/uploads/2024/04/2570_Medallist-per-category-Juniors_Page_3.jpg'
                                alt='Results3'
                            />
                        </div>

                        <h2 className='ResultCategoryImages'>CADETS</h2>
                        <div className='ResultsRowImages'>
                            <img
                                src='https://skopje-open.ramustkd.org/wp-content/uploads/2024/04/2570_Medallist-per-category-Cadets_Page_1.jpg'
                                alt='Results1'
                            />
                            <img
                                src='https://skopje-open.ramustkd.org/wp-content/uploads/2024/04/2570_Medallist-per-category-Cadets_Page_2.jpg'
                                alt='Results2'
                            />
                            <img
                                src='https://skopje-open.ramustkd.org/wp-content/uploads/2024/04/2570_Medallist-per-category-Cadets_Page_3.jpg'
                                alt='Results3'
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default SkopjeOpen;
