import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ImageCopyright } from './Images';

const images = [
    { url: '_MG_0829.webp', position: '80%' },
    { url: 'RSO24.webp', position: '15%' },
    { url: 'SOR23.webp', position: '40%' },
    { url: 'RSO23.webp', position: '25%' },
    { url: 'RSO22.webp', position: '10%' },
    { url: 'RSO22-1.webp', position: '10%' },
    { url: 'RSO21.webp', position: '32%' },
    { url: '_MG_0182-Pano.webp', position: '50%' },
    { url: '_MG_5790-Pano.webp', position: '50%' },
];

const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.03);
  }
`;

const SlideshowContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Slide = styled.img<{ active: boolean; previous: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: ${(props) => (props.active || props.previous ? 1 : 0)};
    ${(props) =>
        props.active
            ? css`
                  animation: ${zoomIn} 3s ease-in-out forwards;
              `
            : props.previous
              ? css`
                    transform: scale(1.03);
                `
              : css`
                    transform: scale(1);
                `}
    transition: opacity 1s ease-in-out;
`;

export const ChangingImage = () => {
    const [index, setIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState<number | null>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setPreviousIndex(index);
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds
        return () => clearInterval(interval);
    }, [index]);

    return (
        <SlideshowContainer>
            <ImageCopyright>{'© S. Dzhoykev'}</ImageCopyright>
            {images.map((image, i) => (
                <Slide
                    key={i}
                    src={`https://ramustkd.org/assets/${image.url}`}
                    style={{ objectPosition: '50% ' + image.position }}
                    alt={document.title + ' Header'}
                    active={i === index}
                    previous={i === previousIndex}
                />
            ))}
        </SlideshowContainer>
    );
};
