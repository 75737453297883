import React, { useEffect, useState } from 'react';
import { Navigation } from './components/Navigation';
import Footer from './components/footer';
import './SofiaOpen.css';
import { HeaderBigImage } from './components/HeaderImage';
import { HeaderElement, HeaderMenu } from './components/HeaderMenu';
import { DocumentIcon, InformationIcon, LivestreamIcon, NewsIcon, ResultsIcon, ScheduleIcon } from './images/Icons';

export default function SofiaOpen() {
    document.title = 'Ramus Sofia Open';

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function loadPosts() {
            const response = await fetch(`https://sofia-open.com/wp-json/wp/v2/posts?categories=39&orderby=date&order=desc&better_featured_image`);
            if (!response.ok) {
                return;
            }
            const posts = await response.json();
            setPosts(posts);
        }
        loadPosts();
    }, []);

    const scrollTo = (ref: HTMLElement | null) => {
        const link = window.location.hash.replace('#', '');

        if (ref && ref.id === link) {
            ref.scrollIntoView();
        }
    };

    return (
        <>
            <Navigation />
            <HeaderBigImage />
            <HeaderMenu>
                <HeaderElement title='Information' viewBox='0 1 80 80'>
                    <InformationIcon />
                </HeaderElement>
                <HeaderElement title='Schedule' viewBox='0 0 80 80'>
                    <ScheduleIcon />
                </HeaderElement>
                <HeaderElement title='Documents' viewBox='0 1 80 80'>
                    <DocumentIcon />
                </HeaderElement>
                <HeaderElement title='Livestream' viewBox='0 0 20 20'>
                    <LivestreamIcon />
                </HeaderElement>
                <HeaderElement title='News' viewBox='1 0.5 18 18'>
                    <NewsIcon />
                </HeaderElement>
                <HeaderElement title='Results' viewBox='0 0 512 512'>
                    <ResultsIcon />
                </HeaderElement>
            </HeaderMenu>
            <div className='SofiaOpen'>
                <div className='Main'>
                    <div className='Information' ref={scrollTo} id='information'>
                        <h2>Information</h2>
                        <span className='line'></span>
                        <div className='InformationGridLeft'>
                            <div className='InformationGridLeftBack'>
                                <img src='https://sofia-open.com/wp-content/uploads/2024/04/MG_5780.jpg' alt='Asics Arena' />
                            </div>
                            <div className='InformationGridLeftFront'>
                                <h3>Venue</h3>
                                <h4>Sports hall “Arena Asics” (ex Festivalna Hall)</h4>
                                <a href='https://goo.gl/maps/UUPaQH7JXoK2'>35 Manastirska str., 1111 Sofia, Bulgaria</a>
                                <p>
                                    Asics Arena has 9,000 square meters of covered sprawling area where attractive competitions of the highest class are held.
                                    The hall has 2270 seats. It is also a cultural center, with opportunities for art exhibitions, trade shows, presentations
                                    and concerts. There is an adjoining restaurant and cafe.
                                </p>
                            </div>
                        </div>
                        <div className='InformationGridRight'>
                            <div className='InformationGridRightBack'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/accomodation.jpg' alt='Asics Arena' />
                            </div>
                            <div className='InformationGridRightFront'>
                                <h3>Accommodation</h3>
                                <h4>Ramada by Wyndham Sofia City Center</h4>
                                <a href='https://maps.app.goo.gl/y8W3y7dRHQiV8F4j9'>Knyagina Maria Luisa Blvd 131, Sofia, Bulgaria</a>
                                <p>
                                    All participants are offered preferential prices by the OC in the partner hotels. Make a reservation through{' '}
                                    <a href='mailto:office@ramustkd.org'>office@ramustkd.org</a>
                                </p>
                                <p>
                                    Transportation is <strong>not </strong>included in the hotel accommodation.
                                </p>
                            </div>
                        </div>
                        <div className='InformationGridLeft'>
                            <div className='InformationGridLeftBack'>
                                <img src='https://sofia-open.com/wp-content/uploads/2024/04/MG_5688.jpg' alt='Asics Arena' />
                            </div>
                            <div className='InformationGridLeftFront'>
                                <h3>Competition Rules</h3>
                                <a href='http://www.worldtaekwondo.org/rules-wt/rules.html'>WT Competition Rules (September 1, 2023)</a>
                                <p>
                                    Single elimination tournament, Classes <strong>A </strong>and <strong>B</strong>
                                </p>
                                <p>Best of three competition format.</p>
                                <p>
                                    Seniors, Juniors, and Cadets (Class <strong>A</strong>): 2(3) x 1,5 m. (30 s.)<br></br>
                                    All matches in Class <strong>B</strong> and Children (Class <strong>A</strong>): 2(3) x 1 m. (30 s. break);
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='Schedule' ref={scrollTo} id='schedule'>
                        <h2>Event Schedule</h2>
                        <span className='line'></span>
                        <img src='https://sofia-open.com/wp-content/uploads/2024/04/rso24-info-scaled.jpg' alt='Event Schedule' />
                    </div>
                    <div className='Documents' ref={scrollTo} id='documents'>
                        <h2>Documents</h2>
                        <span className='line'></span>
                        <div className='DocumentsList'>
                            <a href='https://sofia-open.com/wp-content/uploads/2024/03/RSO2024-OP_rev2301.pdf'>
                                <h3 className='DocumentsListName'>Outline</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Outline' />
                            </a>
                            <a href='https://sofia-open.com/wp-content/uploads/2024/03/RSO24E2_Draw.pdf'>
                                <h3 className='DocumentsListName'>Draw E2</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Draw' />
                            </a>
                            <a href='https://www.tpss2021.eu/Resultdetail?ID=GbKlPMJoCw1KVBmBzX2RZA%3d%3d&EventID=KJHQln%2fsdw3rxb3JA1qZsQ%3d%3d'>
                                <h3 className='DocumentsListName'>Weight-in</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Random Weight-in' />
                            </a>
                            <a href='https://sofia-open.com/wp-content/uploads/2024/03/RSO24E2_Teams.pdf'>
                                <h3 className='DocumentsListName'>Teams</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Teams' />
                            </a>
                            <a href='https://sofia-open.com/wp-content/uploads/2024/03/RSO24E2_Singlecomp.pdf'>
                                <h3 className='DocumentsListName'>Single comp.</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Single competitors' />
                            </a>
                            <a href='https://sofia-open.com/wp-content/uploads/2024/03/RSO2024_Poomsae_Draw.pdf'>
                                <h3 className='DocumentsListName'>Draw Poomsae</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Draw Poomsae<' />
                            </a>
                        </div>
                    </div>
                    <div className='Live' ref={scrollTo} id='livestream'>
                        <h2>Livestream</h2>
                        <span className='line'></span>
                        <div className='LiveRow'>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court1.png' alt='Court 1' />
                                <iframe
                                    src='https://www.youtube.com/embed/q0lDHgZdImw?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court-2.png' alt='Court 2' />
                                <iframe
                                    src='https://www.youtube.com/embed/disgodVDYTg?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court-3.png' alt='Court 3' />
                                <iframe
                                    src='https://www.youtube.com/embed/ulJ7ezn2XOk?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/04/court-4.png' alt='Court 4' />
                                <iframe
                                    src='https://www.youtube.com/embed/zO3zzhGNEyQ?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/11/COURT-5.png' alt='Court 5' />
                                <iframe
                                    src='https://www.youtube.com/embed/nxCybIJUgzk?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                        <div className='LiveRow'>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/11/court6.png' alt='Court 6' />
                                <iframe
                                    src='https://www.youtube.com/embed/fwDCGUhBNlc?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/11/court7.png' alt='Court 7' />
                                <iframe
                                    src='https://www.youtube.com/embed/tQ--W5CfFZ8?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className='LiveElement'>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2024/03/court-8.png' alt='Court 8' />
                                <iframe
                                    src='https://www.youtube.com/embed/5v8_5tV8AIk?si=l1lco-u9CubILTTF'
                                    title='YouTube video player'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div className='News' ref={scrollTo} id='news'>
                        <h2>News</h2>
                        <span className='line'></span>
                        <div className='NewsRow'>
                            {posts.slice(0, 3).map((post: any, index) => {
                                return (
                                    <a key={index} id={`news${index + 1}`} href={`/news?id=${post.id}`}>
                                        <img
                                            src={post.better_featured_image?.source_url || 'https://sofia-open.com/wp-content/uploads/2024/04/MG_5780.jpg'}
                                            alt={post.title.rendered}
                                        />
                                        <h3>{post.title.rendered}</h3>
                                    </a>
                                );
                            })}
                        </div>
                        <a href='/news?i=open' className='NewsAll'>
                            <h3>All news</h3>
                        </a>
                    </div>
                    <div className='Results' ref={scrollTo} id='results'>
                        <h2>Results</h2>
                        <span className='line'></span>
                        <div className='DocumentsList'>
                            <a href='https://www.tpss2021.eu/getEventPDF?filename=2569/Individual_results.pdf'>
                                <h3 className='DocumentsListName'>Results Open</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Results' />
                            </a>
                            <a href='https://www.tpss2021.eu/getEventPDF?filename=2571/Individual_results_E2.pdf'>
                                <h3 className='DocumentsListName'>Results E2</h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Results<' />
                            </a>
                            <a href='https://www.tpss2021.eu/getEventPDF?filename=2616/Results_A.pdf'>
                                <h3 className='DocumentsListName'>
                                    Poomsae<br></br>Class A
                                </h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Results' />
                            </a>
                            <a href='https://www.tpss2021.eu/getEventPDF?filename=2616/Results_B.pdf'>
                                <h3 className='DocumentsListName'>
                                    Poomsae<br></br>Class B
                                </h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Results' />
                            </a>
                            <a href='https://www.tpss2021.eu/getEventPDF?filename=2616/Results_C.pdf'>
                                <h3 className='DocumentsListName'>
                                    Poomsae<br></br>Class C
                                </h3>
                                <img src='https://www.ramustkd.org/wp-content/uploads/2023/09/pdf.png' alt='Results' />
                            </a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
